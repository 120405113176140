import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1280 1280">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g fill={PRIMARY_MAIN} fillRule="evenodd" stroke="none" strokeWidth="1" xmlns="http://www.w3.org/2000/svg"
            transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)">
         
            <path xmlns="http://www.w3.org/2000/svg" d="M5620 12785 c-244 -39 -488 -168 -656 -348 -171 -184 -270 -392 -309 -647 -22 -143 -22 -4877 0 -5020 20 -132 49 -229 100 -338 170 -358 489 -599 872 -657 562 -85 1094 247 1272 793 64 195 61 59 61 2712 0 2655 3 2517 -61 2713 -178 545 -720 880 -1279 792z"/>
            <path xmlns="http://www.w3.org/2000/svg" d="M2510 10355 c-8 -2 -49 -9 -90 -15 -208 -34 -430 -150 -588 -308 -553 -551 -926 -1059 -1231 -1672 -383 -772 -576 -1556 -597 -2430 -34 -1339 407 -2662 1235 -3710 272 -345 645 -717 986 -986 713 -559 1565 -950 2448 -1123 406 -80 677 -105 1127 -105 467 0 735 26 1170 114 2252 455 4055 2258 4510 4510 88 436 113 696 114 1165 0 376 -10 532 -55 840 -160 1102 -637 2138 -1371 2972 -154 175 -260 284 -272 280 -6 -3 -49 26 -96 64 -101 82 -287 178 -410 213 -283 80 -614 42 -867 -100 -678 -379 -808 -1289 -261 -1832 46 -45 109 -101 141 -125 104 -76 320 -369 469 -634 421 -753 543 -1634 343 -2478 -296 -1249 -1271 -2245 -2518 -2575 -490 -129 -1017 -149 -1528 -60 -480 84 -977 291 -1383 574 -309 216 -631 533 -852 841 -455 633 -683 1413 -643 2197 39 763 313 1458 811 2055 105 126 149 171 336 349 220 209 338 471 349 777 6 159 -5 248 -49 392 -54 179 -151 339 -288 475 -174 175 -389 285 -630 325 -82 13 -264 19 -310 10z"/>
          
        </g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
